import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['workspace-invites', 'internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      generateAccountInvite: build.mutation<GenerateAccountInviteApiResponse, GenerateAccountInviteApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/invite`, method: 'POST', body: queryArg.accountInvitationEmail }),
        invalidatesTags: ['workspace-invites', 'internal']
      }),
      getAccountInvites: build.query<GetAccountInvitesApiResponse, GetAccountInvitesApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/invite`, params: { offset: queryArg.offset, per_page: queryArg.perPage } }),
        providesTags: ['workspace-invites']
      }),
      deleteAccountInvite: build.mutation<DeleteAccountInviteApiResponse, DeleteAccountInviteApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/invite/${queryArg.inviteId}`, method: 'DELETE' }),
        invalidatesTags: ['workspace-invites']
      }),
      acceptAccountInvite: build.mutation<AcceptAccountInviteApiResponse, AcceptAccountInviteApiArg>({
        query: (queryArg) => ({ url: `/api/v2/accept-invite`, method: 'POST', body: queryArg.accountInvitationToken }),
        invalidatesTags: ['workspace-invites', 'internal']
      }),
      getUserWorkspaceInvites: build.query<GetUserWorkspaceInvitesApiResponse, GetUserWorkspaceInvitesApiArg>({
        query: () => ({ url: `/api/v2/user/invite` }),
        providesTags: ['workspace-invites']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GenerateAccountInviteApiResponse = /** status 200 Generate a single user invite. Only available to account owners. */ Ok
export type GenerateAccountInviteApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  accountInvitationEmail: AccountInvitationEmail
}
export type GetAccountInvitesApiResponse = /** status 200 Get list of invites with total count. */ AccountInvitationList
export type GetAccountInvitesApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type DeleteAccountInviteApiResponse = /** status 200 Generate a single user invite. Only available to account owners. */ Ok
export type DeleteAccountInviteApiArg = {
  inviteId: string
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
}
export type AcceptAccountInviteApiResponse = /** status 200 Generate a single user invite. Only available to account owners. */ Ok
export type AcceptAccountInviteApiArg = {
  accountInvitationToken: AccountInvitationToken
}
export type GetUserWorkspaceInvitesApiResponse = /** status 200 Get list of invites of the current user with total count. */ AccountInvitationList
export type GetUserWorkspaceInvitesApiArg = void
export type Ok = {
  result?: string
}
export type ApiError = {
  message?: string
}
export type AccountInvitationEmail = {
  email: string
  owns: boolean
}
export type AccountInvitation = {
  created_at?: string
  updated_at?: string
  account_invitation_id: string
  email: string
  account_slug: string
  invitation_token: string
  owns: boolean
}
export type AccountInvitationList = {
  invitations: AccountInvitation[]
  count?: number
}
export type AccountInvitationToken = {
  invitation_token: string
}
